
import { Component, Vue } from "vue-property-decorator";
import { LoyaltyPointsClaims, User } from "@/network/api";
import { LoyaltyPointClaimViewModel, ReturnsStatus, UserViewModel } from "@/api-client";
import InvoicePreview from "@/components/rewards-module/InvoicePreview.vue";
import { formatDate } from "@/utils/formatDate";
import store from "@/store";
import handleError from "@/utils/handleError";
import getFileExtension from "@/utils/getFileExtension";

@Component({
  name: "ClaimDetail",
  props: {
    id: { default: "" },
  },
  components: {
    InvoicePreview,
  },
})
export default class extends Vue {
  isLoading = false;
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  claim: LoyaltyPointClaimViewModel = {
    id: "",
    invoiceRelativeUrl: "",
    invoiceNumber: "",
    invoiceDate: "",
    loyaltyPointsClaimStatusId: ReturnsStatus.Pending,
    reviewComment: "",
    reviewedByUserId: "",
    reviewedDate: "",
    workshopId: "",
    submittedByUserId: "",
    loyaltyPointsClaimStatusName: "",
    reviewedByUserDisplayName: "",
    submittedByUserDisplayName: "",
    pageNumbers: [],
    products: [
      {
        id: '',
        quantity: 0,
        value: 0,
        productSku: "",
        productName: "",
        productId: "",
        loyaltyPointsClaimExtractedDataDocumentLineItem: {
          confidence: 0,
          description: "",
          productCode: "",
          quantity: 0,
          unitPrice: 0,
          loyaltyPointsClaimExtractedDataDocumentId: "",
          id: "",
          matchedProductSkus: []
        }
      },
    ],
    workshopName: "",
    totalValue: 0,
    dateSubmitted: "",
  };
  fromReport = false;
  fromWorkshop: string | null = null;
  workshopId = "";

	get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get fileExtension() {
    if(this.claim.invoiceRelativeUrl) {
      return getFileExtension(this.claim.invoiceRelativeUrl)
    }
    return ""
  }

  created() {
    this.workshopId = store.getters["user/workshopId"];
    this.loadInitialData();

    if (this.$route.query?.viewtype == "report") {
      this.fromReport = true;
    }

    if (this.$route.query?.workshop) {
      this.fromWorkshop = this.$route.query?.workshop as string;
    }
  }

  get isAdmin() {
    return this.$router.currentRoute.name == "ViewClaimAdmin";
  }

  displayDate(date: string | null | undefined) {
    return formatDate(date);
  }

  async loadInitialData(): Promise<any> {
    this.isLoading = true;
    window.scrollTo({ top: 0 });
    if (this.$props.id != "") {
      await LoyaltyPointsClaims.apiLoyaltyPointsClaimsIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.claim = res.data;

            if (this.workshopId && this.workshopId != this.claim.workshopId) {
              store.dispatch("user/setWorkshopId", this.claim.workshopId);
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error);
        });
    }
  }
}
