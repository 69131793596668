
import { Component, Vue } from "vue-property-decorator";
import FileUploader from "@/components/form-items/FileUploader.vue";
import { File, LoyaltyPointsClaims } from "@/network/api";
import { LoyaltyPointClaimViewModel, ReturnsStatus } from "@/api-client";
import { formatDate } from "@/utils/formatDate";
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";
import getFileExtension from "@/utils/getFileExtension";

@Component({
  name: "ResubmitClaim",
  props: {
    id: { default: "" },
  },
  components: {
    FileUploader,
  },
})
export default class extends Vue {
  formSubmit = false;
  isLoading = false;
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  isClaimSubmit = false;
  invoice: any = null;
  currentInvoice: string = "";
  canHandleClaim = {
    manage: false,
  };
  claimDetail: LoyaltyPointClaimViewModel = {
    id: "",
    invoiceRelativeUrl: "",
    invoiceNumber: "",
    invoiceDate: "",
    loyaltyPointsClaimStatusId: ReturnsStatus.Pending,
    reviewComment: "",
    reviewedByUserId: "",
    reviewedDate: "",
    workshopId: "",
    submittedByUserId: "",
    loyaltyPointsClaimStatusName: "",
    reviewedByUserDisplayName: "",
    submittedByUserDisplayName: "",
    pageNumbers: [],
    products: [
      {
        id: '',
        quantity: 0,
        value: 0,
        productSku: "",
        productName: "",
        productId: "",
        loyaltyPointsClaimExtractedDataDocumentLineItem: {
          confidence: 0,
          description: "",
          productCode: "",
          quantity: 0,
          unitPrice: 0,
          loyaltyPointsClaimExtractedDataDocumentId: "",
          id: "",
          matchedProductSkus: []
        }
      },
    ],
    workshopName: "",
    totalValue: 0,
    dateSubmitted: "",
  };

  get fileExtension() {
    if(this.currentInvoice) {
      return getFileExtension(this.currentInvoice)
    }
    return ""
  }

  public updateFile(file: any) {
    this.formSubmit = true;
    this.invoice = file;
    File.filesPost(this.invoice[0].raw)
      .then((res) => {
        if (res.data) {
          this.claimDetail.invoiceRelativeUrl = res.data.relativePath!;
          this.formSubmit = false;
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        handleError(error, true, "An error occurred uploading the file.");
      });
  }

  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageClaims", true);
    this.loadInitialData();
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  displayDate(date: string | null | undefined) {
    return formatDate(date);
  }

  async loadInitialData(): Promise<any> {
    this.isLoading = true;
    LoyaltyPointsClaims.apiLoyaltyPointsClaimsIdGet(this.$props.id)
      .then((res) => {
        if (res.data) {
          this.isLoading = false;
          this.claimDetail = res.data;
          this.currentInvoice = this.claimDetail.invoiceRelativeUrl!;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.formSubmit = false;
        handleError(error);
      });
  }

  submitForm() {
    this.formSubmit = true;
    window.scrollTo({ top: 0 });
    LoyaltyPointsClaims.apiLoyaltyPointsClaimsIdPut(this.claimDetail.id!, { id: this.claimDetail.id!, invoiceRelativeUrl: this.claimDetail.invoiceRelativeUrl! })
      .then((res) => {
        if (res) {
          this.isClaimSubmit = true;
          this.formSubmit = false;
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        handleError(error);
      });
  }
}
