
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { LoyaltyPointsClaimsAdmin, File, Workshops, Product } from '@/network/api'
    import BasicInputField from "@/components/form-items/BasicInputField.vue"
    import BasicDateField from "@/components/form-items/BasicDateField.vue"
    import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"
    import FileUploader from "@/components/form-items/FileUploader.vue";
    import DialogBox from "@/components/DialogBox.vue"
    import {
        ValidationProvider,
        ValidationObserver,
        extend,
        configure,
    } from "vee-validate"
    import { hasClaim } from "@/utils/applicationClaims"
    import store from "@/store";
    import handleError from '@/utils/handleError'
    import sort from '@/utils/sort'

    @Component({
        name: 'NewManualClaim',
        components: {
            BasicInputField,
            BasicDateField,
            BasicSelectorField,
            DialogBox,
            ValidationProvider,
            ValidationObserver,
            FileUploader
        }
    })
    export default class extends Vue {
        isLoading = true
        formSubmit = false
        dialogVisible: boolean = false
        dialogMessage: string = ""
        confirmText: string = "Ok"
        cancelVisible = true
        invoice: any = null
        workshopDdl: Array<{id: string | undefined, name: string | undefined}> = []
        productDdl: Array<{id: string | undefined, name: string | undefined}> = []

        duplicateClaimNumber: any = false
        claim: any = {
            products: []
        }
        canHandleClaim = {
            manage: false
        }

        public updateFile(file: any) {
            this.invoice = file   
            this.formSubmit = true
            File.filesPost(this.invoice[0].raw)
                .then((res) => {
                    if (res.data) {
                        this.claim.invoiceRelativeUrl = res.data.relativePath!
                        this.formSubmit = false
                    }
                })
                .catch((error) => {
                    this.formSubmit = false
                    handleError(error, true, "An error occurred uploading the file.")
                })
        }

        async created() {
            this.canHandleClaim.manage = await hasClaim('ManageClaims', false);
            extend("required", {
                validate(value) {
                    return {
                        required: true,
                        valid: ["", null, undefined].indexOf(value) === -1,
                    };
                },
                computesRequired: true,
                message: "The {_field_} field is required.",
            })

            configure({
                classes: {
                    failed: "validation-error",
                    invalid: "validation-error",
                    required: "validation-error",
                },
            })
        }

        mounted() {
            this.isLoading = false
            this.loadInitialData()
        }

        @Watch('claim.invoiceDate')
        refreshProductPoints() {
            if(!this.claim.products || this.claim.products.length < 1) {
                return false
            }
            this.claim.products.forEach(async (product: any, index: number) => {
                this.claim.products[index].value = await this.getProductPoints(product.productId)
            })
        }

        async loadInitialData() {
        }
        
        scrollTopTable() {
            this.$nextTick(() => { document.getElementById("productSelect")!.scrollIntoView({ behavior: 'smooth' }) })
        }

        submitForm() {
            this.formSubmit = true
            LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminPost(this.claim)
                .then((res) => {
                    if(res) {
                        this.formSubmit = false
                        const claimId = res.data.value!.toString()
                        this.$message.success("Claim review successful")
                        this.$router.push({ name: "ReviewClaimAdmin", params: { id: claimId } })
                    }
                })
                .catch((error) => {
                    this.formSubmit = false
                    this.dialogVisible = false
                    handleError(error)
                });
        }

        handleConfirm() {
            if (this.confirmText === "Yes") {
                this.$router.push({ name: "RewardPointList" })
            }
        }

        beforeBack() {
            let isDirty = document.querySelectorAll(".dirty")
            this.confirmText = "Yes"

            if (isDirty.length > 0) {
                this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>"
                this.dialogVisible = true
            } else {
                this.handleConfirm()
            }
        }

        deleteProduct(index: number) {
            this.claim.products?.splice(index, 1)
        }

        async updateProductList(data: any) {
            const isListed = this.claim.products!.filter((prod: any) => prod.productId == data)
            if(isListed.length > 0) {
                this.$nextTick(() => {
                    const anchor = this.$refs[data]! as Element
                    document.getElementById(anchor.id)!.scrollIntoView({ behavior: 'smooth' })
                })
                return
            }
            const product = this.productDdl.filter(prod => prod.id == data)
            this.claim.products!.push({productId: product[0].id, quantity: 1, productName: product[0].name, value: await this.getProductPoints(product[0].id)})
        }

        getProductPoints(id: string | undefined) {
            if(!id || id == "") {
                return -1
            }
            return new Promise<number>((resolve, reject) => {
                Product.apiProductsValueGet(id, this.claim.invoiceDate || new Date().toISOString())
                    .then((res) => {
                        if (res.data) {
                            resolve(res.data)
                        }
                        else {
                            resolve(-1)
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        handleError(error)
                    })
            })
        }

        getPointTotal() {
            if(!this.claim.products || this.claim.products.length < 1) {
                return -1
            }
            let total: number = 0
            Object.values(this.claim.products!).forEach((product: any) => {
                if(product.value > 0) {
                    total += product.quantity! * product.value
                }
            })
            return total
        }
        
        testInvoiceNumber() {
            if(this.claim.invoiceNumber == null || this.claim.invoiceNumber == "") {
                this.duplicateClaimNumber = false
                return
            }
            LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminGet(1, 99999, undefined, this.claim.invoiceNumber, this.claim.workshopId)
                .then((res) => {
                    if (res.data.data) {
                        this.duplicateClaimNumber = res.data.data!.filter(claim => {
                            return claim.invoiceNumber == this.claim.invoiceNumber
                        })

                        if(this.duplicateClaimNumber.length < 1) {
                            this.duplicateClaimNumber = false
                        }
                    }
                })
        }

        handleWorkshops(open: boolean) {
            if(open) {
                this.workshopDdl = []
            }
        }

        filterWorkshops(value: string) {
            if(value.length >= 3) {
                this.getWorkshops(value)
            } else {
                this.workshopDdl = []
            }
        }

        getWorkshops(value: string) {
            Workshops.apiWorkshopsGet(1, 30, value)
                .then((res) => {
                    if (res.data.data) {
                        this.isLoading = false
                        this.workshopDdl = sort(Object.values(res.data.data!).map(data => { return { id: data.id, name: data.name } }), 'name')
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.dialogVisible = false
                    handleError(error)
                })
        }

        getProducts(value:string) {
            Product.apiProductsGet(1, 30, value)
                .then((res) => {
                    if (res.data) {
                        this.productDdl = sort(Object.values(res.data.data!).map((data: any) => { return { id: data.id, name: data.name + ` (${data.sku})`, value: data.point } }), 'name')
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.dialogVisible = false
                    handleError(error)
                })
        }

        handleProducts(open: boolean) {
            if(open) {
            this.productDdl = []
            }
        }

        filterProducts(value: string) {
            if(value.length >= 3) {
            this.getProducts(value)
            } else {
            this.productDdl = []
            }
        }
    }
