
    import { Component, Vue } from 'vue-property-decorator'
    import PointClaimTable from '@/components/rewards-module/PointClaimTable.vue'
    import { Workshops, LoyaltyPointsClaimsAdmin } from "@/network/api"
    import { WorkshopViewModel, LoyaltyPointClaimStatus, BulkReviewLoyaltyPointsClaimAdminCommand } from "@/api-client"
    import { hasClaim } from "@/utils/applicationClaims"
    import handleError from '@/utils/handleError'
    import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
    import BasicDateRangeField from "@/components/form-items/BasicDateRangeField.vue"
    import BasicInputField from "@/components/form-items/BasicInputField.vue";
    import sort from '@/utils/sort'
    import DialogBox from "@/components/DialogBox.vue"

    @Component({
        name: '',
        components: {
            PointClaimTable,
            BasicSelectorField,
            BasicDateRangeField,
            BasicInputField,
            DialogBox
        }
    })
    export default class extends Vue {
        isLoading = false
        workshopDdl: Array<WorkshopViewModel> = []
        selectedId = ""
        canHandleClaim = {
            manage: false
        }
        createDate = {
            startDate: undefined,
            endDate: undefined,
        }
        listOrder: 'Asc' | 'Desc' = 'Desc';
        listSearch: string = "";
        isFullMatch: string = "";
        dialogVisible: boolean = false;
        dialogMessage: string = "";
        confirmText: string = "Ok";
        cancelVisible = true;
        bulkUpdating = false;
        bulkUpdateRequest: BulkReviewLoyaltyPointsClaimAdminCommand | null = null
        refreshFlag = false;

        async created() {
            this.canHandleClaim.manage = await hasClaim('ManageClaims', false);
        }

        getWorkshops(value:string) {
            Workshops.apiWorkshopsGet(1, 30, value)
                .then((res) => {
                    if (res.data) {
                        this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[]
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }

        handleWorkshops(open: boolean) {
            if(open) {
                this.workshopDdl = []
            }
        }

        filterWorkshops(value: string) {
            if(value.length >= 3) {
                this.getWorkshops(value)
            } else {
                this.workshopDdl = []
            }
        }

        get isFiltered () {
            return this.selectedId || this.createDate.startDate || this.createDate.endDate || this.listSearch || this.fullMatchFlag()
        }

        handleConfirm() {
            if (!this.bulkUpdateRequest) {
                handleError('Error: Bulk Update Request not found');
                return false;
            }

            if (this.confirmText === "Reject Claim") {
                if (this.bulkUpdateRequest.reviewComment != "" && this.bulkUpdateRequest.reviewComment != null) {
                    this.bulkUpdate();
                }
            } else if (this.confirmText === "Send Claim") {
                this.bulkUpdate();
            }
        }

        fullMatchFlag () {
            return this.isFullMatch != ""
        }

        matchedChange(data: any) {
            this.isFullMatch = data == true ? "true" : ""
        }

        orderChange(data: any) {
            this.listOrder = data
        }

        searchChange(data: any) {
            this.listSearch = data
        }

        handleClose() {
            this.bulkUpdating = false;
            this.dialogVisible = false;
            setTimeout(() => {
                this.bulkUpdateRequest = null;
            }, 100);            
        }

        reject() {
            this.dialogVisible = true;
            this.bulkUpdateRequest = {
                searchText: this.listSearch,
                workshopId: this.selectedId || undefined,
                sortDirection: this.listOrder,
                isFullMatch: this.isFullMatch == "true" ? true : undefined,
                dateSubmittedStartDate: this.createDate.startDate || null,
                dateSubmittedEndDate: this.createDate.endDate || null,
                reviewStatusId: LoyaltyPointClaimStatus.Rejected,
                reviewComment: ''
            };
            this.confirmText = "Reject Claim";

            this.dialogMessage = `<h2 class='mt-0 text-center'>Please provide a reason before rejecting the filtered claims </h2>`;
        }

        approval() {
            this.dialogVisible = true;
            this.bulkUpdateRequest = {
                searchText: this.listSearch,
                workshopId: this.selectedId || undefined,
                sortDirection: this.listOrder,
                isFullMatch: this.isFullMatch == "true" ? true : undefined,
                dateSubmittedStartDate: this.createDate.startDate || null,
                dateSubmittedEndDate: this.createDate.endDate || null,
                reviewStatusId: LoyaltyPointClaimStatus.Approved,
                reviewComment: ''
            };
            this.confirmText = "Send Claim";

            this.dialogMessage = `<h2 class='mt-0 text-center'>Confirm approval of the filtered claims?</h2>`;
        }

        bulkUpdate() {
            if (!this.bulkUpdateRequest) {
                handleError('Error: Bulk Update Request not found');
                return false;
            }

            this.bulkUpdating = true;
            const payload: BulkReviewLoyaltyPointsClaimAdminCommand = { ...this.bulkUpdateRequest };

            LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminBulkReviewLoyaltyPointsClaimAdminPut(payload)
                .then((res) => {
                    if (res) {
                        this.bulkUpdating = false;
                        this.dialogVisible = false;
                        setTimeout(() => {
                            this.bulkUpdateRequest = null;
                        }, 100);
                        this.$message.success("Claim review successful");
                        this.refreshFlag = true;
                    }
                })
                .catch((error) => {
                    this.bulkUpdating = false;
                    this.dialogVisible = false;
                    setTimeout(() => {
                        this.bulkUpdateRequest = null;
                    }, 100);
                    handleError(error);
                });
        }
    }
