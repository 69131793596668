
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import FileUploader from "@/components/form-items/FileUploader.vue";
    import { File, LoyaltyPointsClaims } from "@/network/api"
    import { SubmitLoyaltyPointsClaimCommand } from "@/api-client"
    import store from "@/store";
    import handleError from '@/utils/handleError';

    @Component({
        name: 'SubmitClaim',
        components: {
            FileUploader
        }
    })
    export default class extends Vue {
        @Watch('$route.query', { immediate: true })
        private onRouteChange(query: any) {
            if (!query.hasOwnProperty('submitted')) {
                this.isClaimSubmit = false
                this.loadInitialData()
            }
        }
        isLoading = false
        isClaimSubmit = false
        invoice: any = null
        claimSubmission: SubmitLoyaltyPointsClaimCommand = {
            invoiceRelativeUrl: "",
            workshopId: ""
        }

        public updateFile(file: any) {
            this.isLoading = true
            this.invoice = file
            File.filesPost(this.invoice[0].raw)
                .then((res) => {
                    if (res.data) {
                        this.claimSubmission.invoiceRelativeUrl = res.data.relativePath!
                        this.isLoading = false
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error, true, "An error occurred uploading the file.")
                })
        }

        created() {
            this.loadInitialData()
        }

        get workshopId() {
            return store.getters["user/workshopId"];
        }

        async loadInitialData(): Promise<any> {
            this.isLoading = true
            this.claimSubmission.workshopId = this.workshopId
            this.isLoading = false
        }

        submitForm() {
            this.isLoading = true
            window.scrollTo({ top: 0 })
            LoyaltyPointsClaims.apiLoyaltyPointsClaimsPost(this.claimSubmission)
                .then((res) => {
                    if (res.data) {
                        this.isClaimSubmit = true
                        this.isLoading = false
                        this.$router.push({query: { submitted: 'true'}})
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }

        instructions = [
            {
            icon: 'media',
            copy: 'Take a picture of your invoice.'
            },
            {
            icon: 'messagebox',
            copy: 'Submit it to ServiceBay - click on the submit invoice button and follow the prompts.'
            },
            {
            icon: 'trophy',
            copy: 'Once the invoice is verified by G.U.D., GBucks will be allocated for each G.U.D. product on the invoice.'
            }
        ]

    }
